import React, { useState } from 'react'
import Layout from '../../../components/layouts'
import Banner from '../../../components/features/Banner'
import SEO from '../../../components/common/SEO';
import ReactWOW from 'react-wow';
import FeatureCards from "../../../components/features/FeatureCards"

export const query = graphql`
  query Inspections {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`

const escape = '"< 2/32in"'

export default ({ data }) => {
  const [toggler, setToggler] = useState(false)
  const lightboxProps = {
    toggler: toggler,
    setToggler: setToggler,
    sources: ["https://www.youtube.com/watch?v=xE2yXvWB038"],
  }

  const bannerProps = {
    title: 'Inspections',
    subtitle: `There is more to inspections than meets the eye glass. The practice of examining your workplace or tools, comparing what is observed to a predetermined checklist, and recording on a form that the inspection took place are all great steps towards making your workplace safer, but the real value is in what takes place after the inspection.`,
    featureName: 'inspections',
    rightButtonText: 'Why Certificate Tracking Software?',
    rightButtonSrc: 'https://www.youtube.com/watch?v=xE2yXvWB038',
    mockupImg: '/features/inspections/feature-page-header-inspections.png'
  }

  const seo = {
    title: 'Workhub | Free Inspections Software Hazards Equipment Machinery',
    description: 'Our Inspections Software makes it easy to identify risk, monitor workplace safety & inspect equipment, machinery & tools so you can reduce hazards and boost productivity.',
    image: '/features/inspections/social-card.jpg',
    // pathname,
    // article
  }

  const featureIndex = [
    {
      title: "Customized Checklists",
      para: "Create your checklist of items to inspect and add guidance to help workers complete them all online.",
    },
    {
      title: "Scheduled Intervals",
      para: "Assign a frequency in which inspections of assets or sites must occur. ",
    },
    {
      title: "View Trends",
      para: "See detailed history for each check and track its changes over time or look up the inspection history for an asset or site. ",
    },
    {
      title: "Follow up Actions",
      para: "Approve, comment, or create action items from submitted inspections to track that concerns are being dealt with appropriately.",
    },
    {
      title: "Approval",
      para: "Inspections requiring a review will appear as outstanding in the Administrator dashboard.",
    },

  ]


  return (
    <Layout {...lightboxProps}>
      <SEO {...seo} {...data.site} />
      <Banner {...bannerProps} {...lightboxProps} />

      <main className="feature-main">





        <section className="feature-section">
          <div className="container" >
            <div className="row switch-children">

              <div className="col-lg-6 feature-img">

                <ReactWOW animation="fadeInLeft" delay="0.9s">
                  {/* <img
            className="feature-img-right push-down"
            src="\features\inspections\inspections-computer.png"
            alt="Inspections Admin View"
          /> */}

                  <img
                    className="feature-img-right push-down"
                    src="/features/inspections/inspections-cartoon.png"
                    alt="Inspections Admin View"
                  />
                </ReactWOW>

              </div>


              <div className="col-lg-6 feature-text">

                <h3>The Inspection Checklist</h3>
                <p>A well-executed inspection requires a checklist of hazards to watch for and/or controls that must be in place to ensure that a worksite can be occupied or that piece of equipment is in good working order.</p>
                <p >Every work site or piece of equipment could be different, and so your checklists should be different too. A hazard assessment will help your safety team identify the comprehensive hazard/control list for each worksite or equipment category. </p>




              </div>
            </div>
          </div>
        </section>


        <section className="feature-section">
          <div className="container" >
            <div className="row">


              <div className="col-lg-6 feature-text">

                <h3 style={{ marginTop: '1rem' }}>Inspection Check States</h3>
                <p>Although most checklist items might fall into the "Pass", "Warning" or "Fail" states, you might want to rename or even define those states. Pass might be better labeled as "Locked", and Fail might be better labeled as {escape}. You can even provide guidance tips to properly instruct inspectors how to determine if the state threshold has been met, such as "Less than entire head visible on penny."</p>
                <p>The N/A (not applicable) state can be enabled or disabled for any checklist item, depending on whether that check can be excluded under certain circumstances. </p>

                <strong style={{ fontSize: '18px' }}>Still More Inspection State Settings</strong>
                <p style={{marginBottom: '0px'}}>Now comes the fun part - choosing what happens to each check under the various inspection states.</p>

                <ul className="checklist">
                  <li>Comments are always required on Warning and Fail states, but you can also require them for Pass or N/A states on certain checks. </li>
                  <li>Photos are always optional for any check, but you can make them required for certain states (Fail comes to mind)</li>
                  <li>Decide which Warning or Fail checks require subsequent administrator review and approval.</li>
                  <li>Choose which failed checks are "Critical Issues" (in which case the system will notify the specified recipients).</li>
                  <li>Choose which failed checks should automatically move the Asset being inspected to "Out of Service".</li>
                </ul>



              </div>

              <div className="col-lg-6 feature-img">

                <ReactWOW animation="fadeInRight" delay="0.9s">

                  <img
                    className="feature-img-left push-down"
                    src="/features/inspections/inspections-tablet.png"
                    alt="Inspections Tablet View"
                  />

                </ReactWOW>

              </div>

            </div>
          </div>
        </section>


        <section className="feature-section">
          <div className="container" >
            <div className="row switch-children">

              <div className="col-lg-6 feature-img">

                <ReactWOW animation="fadeInLeft" delay="0.9s">
                  <img
                    className="feature-img-right push-down"
                    src="/features/inspections/inspections-computer.png"
                    alt="Inspections Admin View"
                  />

                </ReactWOW>

              </div>


              <div className="col-lg-6 feature-text">

                <h3>Inspection Assignments and Frequency</h3>
                <p>Inspections can be assigned to one or more locations, or one or more equipment categories. If you do decide to make an inspection required for certain locations or equipment categories, then you must decide the frequency. Is it required every 5 days, or every 6 months - this will affect how and when coming due and past due warnings appear on dashboards for workers that are assigned to those locations or assets."</p>

                <h3>The Inspection</h3>
                <p>
                  The inspection itself can be easily performed on a smart phone. The inspector simply chooses the location (or asset) for which the inspection is being done. If the inspection is for an asset, the inspector may be required to enter an odometer or hour reading.</p>

                <p>
                  Then it's a matter of performing each check and choosing from the Pass, Warning, Fail or N/A states. Depending on the state chosen for each check, the inspector may be required to post a comment and/or take a photo. Then they click "Submit", and from there the inspection submission goes through the appropriate administrative workflow.</p>

              </div>
            </div>
          </div>
        </section>


        <section className="feature-section">
          <div className="container" >
            <div className="row">


              <div className="col-lg-6 feature-text">

                <h3>Administrator Review and Approval</h3>
                <p>Inspections meeting the criteria of requiring a review (based on the aforementioned check settings) will appear as outstanding in the Administrator dashboard. Add Action Items, Comments, or simply Approve the appropriate inspection checks. </p>

                <p>The statistics compile automatically for each inspection, and are plotted over time on the location or asset level, and statuses are updated (until the next time they’re due).</p>

              </div>

              <div className="col-lg-6 feature-img">
                <ReactWOW animation="fadeInRight" delay="0.9s">

                  <img
                    className="feature-img-left push-down"
                    src="/features/inspections/inspections-tablet-two.png"
                    alt="Inspections Tablet View"
                  />

                </ReactWOW>
              </div>

            </div>
          </div>
        </section>









        {/* FEATURE OVERVIEW */}

        <section className="feature-overview-container">
          <h2>Overview</h2>

          <div className="feature-overview-grid">
            {featureIndex.map(item => (
              <FeatureCards title={item.title} para={item.para} />
            ))}
          </div>
        </section>

      </main>


    </Layout>
  )
}